.side-nav-group-container {
    border-radius: var(--borderRadius);
}

.side-nav-group-content {
    padding-left: var(--sideNavPaddingLeft);
    overflow: hidden;
}

.side-nav-expander-container {
    color: white;
    padding-right: 3em;
}

.side-nav-group-expander {
    transition: all 0.15s ease;
    user-select: none;
}

.side-nav-group-expander-expanded {
    transform: rotate(90deg);
}

@media screen and (max-width: 1200px) {
    .side-nav-expander-container {
        padding-right: 5px;
    }
}

@media (any-pointer) {
    .side-nav-expander-container:hover {
        cursor: pointer;
    }
}