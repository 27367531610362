.button {
    outline: none;
    border-radius: var(--borderRadius);
    overflow: hidden;
    height: fit-content !important;
    width: fit-content;
    line-height: 1.8em;
    transition: all 0.1s ease;
    background-color: transparent;
    width: fit-content;
    min-width: fit-content;
    border: 2px solid transparent;
    user-select: none;
    text-decoration: none;
    padding: .375em .75em;
}

.button-solid,
.button-outline {
    padding: .275em 1.25em;
}

.button-hyperlink {
    text-decoration: underline !important;
}

.button-text {
    height: 100%;
    text-decoration: none !important;
}

.button:disabled {
    filter: saturate(0.2);
    opacity: 0.2;
}

.button-background {
    transition: all 0.1s ease;
    opacity: 0.9;
}

@media screen and (any-pointer) {

    .button-solid:hover:not(:disabled) {
        background-color: #000000;
        filter: none;
        cursor: pointer;
    }

    .button-solid:hover:not(:active):not(:disabled) .button-background {
        opacity: 1;
    }

    .button-solid:active:not(:disabled) .button-background {
        opacity: 0.8;
    }
}

