.message-history-item {
    background-color: var(--primaryAccent);
    border-bottom-left-radius: var(--borderRadius);
    border-top-right-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
    color: white;
    opacity: 0.7;
}

.message-history-item-current {
    background-color: var(--primaryAccent);
    opacity: 1;
}
