.tenant-onboarding-wizard-container {
    width: 50vh;
    min-height: 25vh;
    overflow-x: hidden;
}

.tenant-domain-field-invalid {
    border-color: var(--error) !important;
}

.tenant-domain-field-valid {
    border-color: var(--success) !important;
}

.selected-country {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
}