@keyframes copyDnsButtonCooldown {
    to {
        max-width: 0;
    }
}

.copy-dns-entry-btn {
    border: 1px solid var(--bs-gray-300);    
    border-radius: 5px;
    outline: none;
    background-color: white;
    color: var(--bs-gray-700);
}

.copy-dns-entry-btn:disabled {
    border-color: var(--success);
    color: var(--success);
}

.copy-dns-entry-btn-background {
    opacity: 0.1;
    max-width: 100%;
}

.copy-dns-entry-btn:disabled .copy-dns-entry-btn-background {
    background-color: var(--success);
    animation: copyDnsButtonCooldown 1s linear;
}

@media (any-pointer) {
    .copy-dns-entry-btn:hover:not(:disabled) {
        background-color: var(--bs-gray-300);
    }   
}