.form-check-input:checked {
    background-color: var(--secondary);
    border: none !important;
}

@media (any-pointer) {
    
    .check-box:hover,
    .form-check-input:hover,
    .check-box-label:hover {
        cursor: pointer;
    }

    .form-check-input:active,
    .form-check-input:focus {
        border-color: var(--secondary) !important;
    }
}