.hyperlink {
    color: var(--secondary);
    text-decoration: none;
    transition: all 0.15s ease;
    border-radius: 3px;
}

@media (any-pointer) {
    .hyperlink:hover {
        color: var(--muted);
    }
}