.color-picker {
    padding: 0;
    border: none;
    border-radius: var(--borderRadius);
    overflow: hidden;
    width: 100px;
}

@media screen and (any-pointer) {
    .color-picker:hover,
    .color-picker:active {
        padding: 2px;
        cursor: pointer;
    }
}