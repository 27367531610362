.multi-page-wizard-page {
    min-width: 100%;
    width: fit-content;
    height: fit-content;
    transition: all 0.05s ease-in;
    opacity: 0;
}

.multi-page-wizard-page-active {
    opacity: 1;
    transition: all 0.3s ease-in !important;
}