.editable-ticket-message-field {
    background-color: transparent;
    width: 100%;
    height: 100%;
    resize: none;
    font-family: inherit;
    border-radius: var(--borderRadius);
    max-height: 300px;
    outline: none;
    color: inherit;
    cursor: inherit;
}

.ticket-message-edit-options {
    border: 1px solid transparent;
    border-radius: var(--borderRadius);
    color: white !important;
    z-index: 999999;
    width: 200px;
    overflow: hidden;
}

.editable-ticket-message-field-short {
    overflow: hidden;
}
