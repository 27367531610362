@keyframes drawerAppear {
}

.drawer {
    position: fixed;
    width: 100%;
    height: 100vh;
    right: 0;
    top: 0;
    z-index: 9999;
    background-color: white;
    box-shadow: 0px 0px 8px var(--shadow);
    transition: all 0.15s ease;
    animation: drawerAppear 0.15s ease;
}

@media screen and (max-width: 1000px) {
    .drawer {
        max-width: 100vw;
    }
}

@media screen and (min-width: 1000px) {
    .drawer {
        padding-top: calc(var(--pageBorderRadius) / 1.5) !important;
        max-width: 50vw;
        border-top-left-radius: var(--pageBorderRadius);
    }
}

@media screen and (min-width: 1500px) {
    .drawer {
        max-width: 30vw;
    }
}