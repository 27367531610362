.ticket-state-banner {
    width: fit-content;
    height: fit-content;
    height: 33px;
    min-width: 33%;
    max-width: 192px;
}

.ticket-state-banner-start-right {
    border-top-left-radius: var(--borderRadius);
    border-bottom-left-radius: var(--borderRadius);
    justify-content: right;
}

.ticket-state-banner-start-left {
    border-top-right-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
}
