.log-in-card {
    width: 50%;
}

@media screen and (max-width: 1000px) {
    .log-in-form {
        width: calc(100% - var(--bodyMargin) - var(--bodyMargin));
    }
}

@media screen and (min-width: 1000px) {
    .log-in-form {
        width: 50%;
    }
}