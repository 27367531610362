.card-container {
    border-radius: var(--borderRadius);
    overflow: hidden;
    background-color: #F2F5F9;
}

@media (any-pointer) {
    .card-clickable:hover {
        cursor: pointer;
        background-color: var(--backgroundDarker) !important;
    }

    .card-clickable:hover .card-header {
        cursor: pointer;
        background-color: var(--muted) !important;
    }
}