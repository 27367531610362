@keyframes assignToTeamMemberPopUpAppear {
    from {
        max-height: 0;
    }
}

.assign-ticket-to-teammember-popup {
    animation: assignToTeamMemberPopUpAppear 0.15s ease;
    background-color: white;
    transition: all 0.15s ease;
    max-height: 200px;
    height: 100%;
    border: 1px solid var(--backgroundDarker);
    border-radius: var(--borderRadiusButton);
    overflow: hidden;
    z-index: 10;
}

.assign-ticket-to-teammember-expanded {
    transform: translateY(0);
    opacity: 1;
}

.assign-ticket-to-teammember-button-text {
}

@media (any-pointer) {
    .assign-ticket-to-teammember-button:hover {
        background-color: var(--backgroundDarker);
        cursor: pointer;
    }
}