.table-cell {
    border: none !important;
    width: fit-content;
    padding: 0 !important;
}

.table-cell-density-header {
    padding: 0px 2px !important;
}

.table-cell-density-standard {
    padding: 2px !important;
}

.table-cell-density-comfortable {
    padding: 10px !important;
}

.table-cell-density-condensed {
    padding: 2px !important;
}

.table-cell-header {
    top: 0;
    z-index: 9;
}

.resizer {
    transition: all 0.15s ease;
    width: 5px;
    height: 32px; 
    user-select: none;
}

@media (any-pointer) {
    .resizer:hover {
        cursor: col-resize;
        background-color: var(--primary);
    }
}