@keyframes radioIndicatorAppear {
    from {
        transform: scale(0);
    }
}

.radio-indicator-container {
    border-radius: 50px;
    height: 20px;
    width: 20px;
    overflow: hidden;
    border: 1px solid var(--backgroundDarker);
    transition: all 0.15s ease;
}

.radio-indicator-container-active {
    /* border-color: var(--primaryAccent); */
}

.ticket-type-radio-container-background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
}

.radio-active-indicator {
    animation: radioIndicatorAppear 0.15s ease;
    position: absolute;
    height: 10px;
    width: 10px;
    top: 4px;
    left: 4px;
    border-radius: 50px;
    background-color: var(--primaryAccent);
}

.radio-label-active {
    color: var(--primaryAccent)
}

@media (any-pointer) {
    .radio:hover {
        cursor: pointer;
    }
    
    .radio:hover .radio-indicator-container {
        background-color: var(--backgroundLighter);
        border-color: var(--primaryAccent);
    }
}