.sidenav-element-icon-container {
    min-width: var(--sideNavPaddingLeft);
    max-width: var(--sideNavPaddingLeft);
    user-select: none;
    overflow: hidden;
}

.sidenav-element-label {
    user-select: none;
}

.sidenav-element-icon {
    color: var(--secondary);
}

.sidenav-element {
    min-height: 50px;
    height: 50px;
}

.sidenav-element-icon-container-inside-group {
    min-width: calc(var(--sideNavPaddingLeft) / 2);
    max-width: calc(var(--sideNavPaddingLeft) / 2);
}
