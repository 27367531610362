.label-button {
    outline: none !important;
    border: none !important;
    align-self: center;
    background: none !important;
    transition: all 0.1s ease;
}

@media screen and (any-pointer) {
    .label-button:hover:not(:disabled) {
        cursor: pointer;
        text-decoration: underline;
    }
}