@keyframes ticketMessageReadMetadataAppear {
    from {
        border-radius: 100%;
        transform: scale(0);
    }

    50% {
        transform: scaleX(40%);
    }
}

.ticket-message-read-metadata-container {
    background-color: #ffffff;
    color: black;
    width: fit-content;
    z-index: 0;
    right: 0;
    padding-right: 6px;
    padding-top: 3px;
    transform: translateX(6px);
    transform-origin: right;
    animation: ticketMessageReadMetadataAppear 0.15s linear;
    border-radius: 5px;
}

@media (any-pointer) {
    .ticket-message-read-indicator-read {
        cursor: pointer;
    }    
}