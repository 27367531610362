.show-only-clients-for-current-user-button {
    background-color: var(--backgroundDarker);
    transition: all 0.15s ease;
    height: 42px;
    border-radius: var(--borderRadius);
    user-select: none;
}

@media (any-pointer) {
    .show-only-clients-for-current-user-button:hover {
        cursor: pointer;
        background-color: var(--backgroundDarkest);
    }
}