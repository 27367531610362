.sort-tickets-btn {
    user-select: none;
    border-radius: var(--borderRadius);
    background-color: var(--backgroundDarker);
    min-width: 125px;
}

@media (any-pointer) {
    .sort-direction-switcher:hover {
        cursor: pointer;
    }

    .sort-tickets-btn:hover {
        cursor: pointer;
        background-color: var(--backgroundDarkest);
    }
}