.ticket-statistics-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}

@media screen and (max-width: 450px) {
    .ticket-statistics-grid {
        grid-template-columns: 1fr;
    }
}