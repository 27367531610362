.user-clients-field-array-client {
    border: 1px solid var(--bs-gray-300);
    border-color: var(--bs-gray-300);
    background-color: var(--bs-gray-100);
    border-radius: var(--borderRadius);
}

.user-clients-field-array-favorite-icon {
    color: gold;
}

.user-clients-field-array-client-favorite {
    border-color: gold;
    background-color: rgba(255,215,0, 0.2);
}