@keyframes modalBannerAppear {
    from {
        transform: translateY(-25%);
        background-color: transparent;
        opacity: 0;
        max-height: 0px;
    }
}

@keyframes modalBannerDisappear {
    50% {
        background-color: transparent;
    }

    to {
        transform: translateY(-100%);
        background-color: transparent;
        opacity: 0;
        max-height: 0px;
    }
}

.modal-banner {
    max-height: var(--menuBarHeight);
    position: fixed;
    top: 20px;
    box-shadow: 1px 1px 150px #000000A0, 1px 1px 10px #0000003d;
    left: 20px;
    width: calc(100% - 40px);
    overflow: hidden;
    padding: 12px;
    border-radius: 10px;
    z-index: 999999999;
    animation: modalBannerAppear 0.2s ease, modalBannerDisappear 0.5s ease 3.6s;
}