.tab {
    z-index: 1;
    color: var(--primary);
    /* transition: color 0.15s ease; */
    user-select: none;
    border-bottom: 3px solid transparent;
}

.tab-standard {
    line-height: 16px;
    font-size: 16px;
}

.tab-standard-active,
.tab-muted-active {
    border-bottom-color: var(--secondary);
}

.tab-muted {
    color: var(--muted);
}

.tab-tiny {
    font-size: 12px;
    color: var(--muted) !important;
    border-bottom: none;
}

.tab-tiny-active * {
    color: var(--primary) !important;
    font-weight: bold !important;
}

@media (any-pointer) {
    .tab:hover {
        cursor: pointer;
    }

    .tab-standard:hover,
    .tab-muted:hover {
        color: var(--primary);
        border-bottom-color: var(--primary);
    }
}