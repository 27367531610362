.ticket-notes {
    background-color: var(--grey);
    border-top-right-radius: var(--borderRadius);
    border-top-left-radius: var(--borderRadius);
}

.ticket-notes-expander-handle {
    overflow: hidden;
    transition: all 0.15s ease;
    border-top-right-radius: var(--borderRadius);
    border-top-left-radius: var(--borderRadius);
    height: 32px;
}

.ticket-notes-expander-handle-expanded {
    background-color: var(--grey);
    
}

.ticket-expander-icon {
    transition: all 0.3s ease;
}

.ticket-expander-icon-expanded {
    transform: rotate(180deg);
}

.ticket-notes-expander-text {
    text-transform: uppercase;
}

.ticket-notes-container {
    transition: all 0.3s ease;
    height: 70vh;
    overflow-y: auto;
}

.ticket-notes-container-hidden {
    max-height: 0;
    opacity: 0;
}

.ticket-notes-container-expanded {
    max-height: 50vh;
}

.ticket-notes-note-text-field {
    background-color: transparent;
    border: none;
    resize: none;
    width: 100%;
    height: calc(100% - 5px);
    outline: none;
}

.ticket-notes-new-note-form {
    width: 200px;
    height: 100px !important;
    max-height: 24px;
    max-width: 24px;
    margin-right: 200px !important;
    transition: all 0.3s ease-in;
    border-radius: var(--borderRadius);
}

.ticket-notes-new-note-form-expanded {
    max-height: 200px;
    margin-right: 0 !important;
    background-color: var(--muted);
    max-width: 200px;
}

.ticket-notes-new-note-icon {
    transition: all 0.3s ease;
    transform: rotate(-45deg);
}

.ticket-notes-new-note-icon ~ span {
    white-space: nowrap !important;
}

.ticket-note {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    height: fit-content;
    width: fit-content;
    max-width: 400px;
    border-radius: var(--borderRadius);
}

.ticket-notes-save-button {
    opacity: 0;
    visibility: hidden;
    transition: none;
}

.ticket-notes-save-button-expanded {
    transition: all 0.3s ease-in;
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}

.ticket-notes-new-note-form-content {
    opacity: 0;
    height: 100%;
    width: 100%;
    max-height: 0;
    max-width: 0;
    transition: max-width 0.15s ease, max-height 0.15s ease;
}

.ticket-notes-new-note-form-content-expanded {
    max-height: 100%;
    max-width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.ticket-notes-new-note-icon-expanded {
    transform: rotate(0deg);
}

@media (any-pointer) {
    .ticket-notes-expander-handle:hover {
        background-color: var(--muted);
        cursor: pointer;
    }
}