.separator {
    background-color: var(--backgroundDarker);
    height: 100%;
    width: 1px;
}

@media screen and (min-width: 1000px) {
    .office-inbox-folders-expander-icon {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 1000px) {
    .office-inbox-folders-content {
        position: absolute;
        background-color: white;
        z-index: 999;
    }

    .office-inbox-folders-content-hidden {
        max-width: 0;
        overflow: hidden;
    }

    .office-inbox-folders-content-visible {
        max-width: 100%;
        min-width: 250px;
        width: 100%;
    }
}