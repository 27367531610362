.office-inbox-folders {
    overflow-y: auto;
    transition: all 0.3s ease;
}
.separator {
    background-color: var(--backgroundDarker);
    height: 100%;
    width: 1px;
}

@media screen and (min-width: 1000px) {
    .office-inbox-folders {
        min-width: 200px;
        max-width: 250px;
    }

    .office-inbox-folders-expander-icon {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 1000px) {
    .office-inbox-folders-title {
        display: none;
        visibility: hidden;
    }

    .separator-folders {
        width: 0;
    }

    .office-inbox-folders {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .office-inbox-folders-mobile-popout-closed {
        max-width: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .office-inbox-folders-mobile-popout-open {
        max-width: 100%;
    }

    .office-inbox-mail-list {
        width: 100%;
    }
}