.accordion-expander-container {
    user-select: none;
}

.accordion-content-container {
    transition: all 0.15s ease;
}

.accordion-content-container-collapsed {
    max-height: 0;
    overflow: hidden;
}

@media (any-pointer) {
    .accordion-expander-container {
        cursor: pointer;
    }
}