.search-box {
    background-color: white !important;
    border-radius: var(--borderRadius) !important;
    border: none !important;
    outline: none !important;
}

.search-box-shadow {
    box-shadow: 0px 0px 4px var(--shadow) !important;
}

.search-box::placeholder {
    color: var(--muted) !important;
    font-size: 12px !important;
}

.search-box-focus {
    box-shadow: 0px 0px 8px var(--muted) !important;
}

@media screen and (any-pointer) {
    
    .search-box:hover:not(.search-box-focus)::placeholder {
        color: var(--secondary) !important;
    }
}