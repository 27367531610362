@keyframes assigTicketToOtheTeamMembersMenuAppear {
    from {
        max-height: 0;
    }
}

.assign-ticket-to-other-team-menu-toggle-disabled {
    background-color: var(--backgroundDarker);
    color: var(--bs-gray-600);
}

.assign-ticket-to-other-team-members-menu {
    right: 105%;
    top: 0;
    background-color: white;
    border-radius: var(--borderRadiusButton);
    border: 1px solid var(--backgroundDarker);
    width: fit-content;
    z-index: 5;
}

.assign-to-other-team-member-popup {
    animation: assigTicketToOtheTeamMembersMenuAppear 0.3s ease;
    background-color: var(--background);
    max-height: 200px;
    height: 100%;
    overflow-y: auto;
}

@media (any-pointer) {
    .assign-ticket-to-other-team-menu-toggle-enabled:hover {
        background-color: var(--backgroundDarker);
        cursor: pointer;
    }

    .assign-ticket-to-other-team-menu-toggle-enabled-team-visible:hover {
        cursor: pointer;
    }

    .assign-ticket-to-other-team-member-button:hover {
        background-color: var(--backgroundDarker);
        cursor: pointer;
    }
}