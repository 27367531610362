.office-folder {
    overflow: hidden;
    border: 1px solid transparent;
}

.office-folder-active {
    color: var(--primary) !important;
    font-weight: bold !important;
}

.office-folder-background {
    width: 100%;
    height: 100%;
}

.office-folder-background-active {
    background-color: var(--muted);
    opacity: 0.5;
}

@media (any-pointer) {
    .office-folder:hover {
        cursor: pointer;
    }

    .office-folder:hover .office-folder-background {
        background-color: var(--muted);
        opacity: 0.3;
    }
}

@media screen and (max-width: 1000px) {
    .office-folder {
        min-height: 60px;
    }   
}