.tooltip-container {
    z-index: 9999;
    background-color: white;
    position: fixed;
    min-width: 100px;
    min-height: 20px;
    box-shadow: 0px 0px 8px var(--shadow);
    border-radius: var(--borderRadius);
}

.tooltip-bottom {
    top: calc(100% + 10px);
}

.tooltip-top {
    bottom: calc(100% + 10px);
}