.side-nav-logo {
    width: auto;
    height: 24px;
    object-fit: cover
}

.side-nav-logo-logo-only {
    align-self: center;
    justify-self: center;
    height: 128px;
}