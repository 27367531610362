.ticket-search-box {
    background-color: white;
    outline: none;
    border: none;
    border-radius: var(--borderRadius);
    width: 220px;
    font-size: 12px;
    box-shadow: 0px 0px 8px var(--shadow) !important;
    line-height: 12px;
    transition: all 0.15s ease;
}

.ticket-search-box::placeholder {
    color: #CCCCCC
}

@media (any-pointer) {
    .ticket-search-box:focus {
        border-color: var(--backgroundDarkest);
        background-color: var(--backgroundLighter);
    }
}