@keyframes otherTicketChatMessageAppear {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes myTicketChatMessageAppear {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
}

.ticket-chat-message-preview-peek {
    height: 100% !important;
}

.ticket-message-history-preview-container {
    position: absolute;
    top: 120%;
    width: 100%;
    height: fit-content;
    opacity: 0.75;
    z-index: 0;
}

.ticket-chat-message {
    width: 95% !important;
    height: fit-content;
}

.ticket-chat-message-other {
    border-bottom-right-radius: var(--borderRadius);
    animation: otherTicketChatMessageAppear 0.15s ease;
}

.ticket-chat-message-own {
    border-top-left-radius: var(--borderRadius);
    animation: myTicketChatMessageAppear 0.15s ease;
}