.verify-log-in-otp-container  {
    gap: 10px;
    display: flex;
    flex: row;
    width: fit-content;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1000px) {
}