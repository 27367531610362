.ticket-attachments-drag-drop-field {
    border: 2px dashed transparent;
    transition: all 0.15s ease;
}

.ticket-attachments-drag-drop-field-hover {
    border-color: var(--primaryAccent);
}

.ticket-chat-message-input {
    box-shadow: 0px 0px 8px var(--shadow) !important;
    transition: all 0.3s ease;
    width: 100%;
    border-radius: var(--borderRadius);
}

