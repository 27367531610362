@keyframes popUpToastAppear {
    from {
        transform: scale(0) translateX(-100%);
        opacity: 0;
    }
}

.pop-up-toast-handle {
    z-index: 1;
}

.pop-up-toast-content {
    left: 120%;
    z-index: 0;
    top: -50%;
    background-color: black;
    padding: 3px 8px;
    user-select: none;
    border-radius: 4px;
    min-width: 200px;
    animation: popUpToastAppear 0.3s ease;
}

@media (any-pointer) {
    .pop-up-toast-handle:hover,
    .pop-up-toast-content:hover {
        cursor: pointer;
    }
}