.ticket-escalation-chip-active {
    background-color: #43ff6433 !important;
    border: 1px solid #43Aa64 !important;
}

.ticket-escalation-chip-active * {
    color: #43Aa64 !important;
}

.ticket-escalation-chip-inactive {
    background-color: #F0F0F0 !important;
    border: 1px solid #F0F0F0 !important;
}

.ticket-escalation-chip-read-only {
    font-size: 14px;
    font-weight: bold;
}