:root {
    --bright: #FFFFFF !important;

    --primary: #002E7F !important;
    --secondary: #507DBC !important;
    --muted: #B2C0D9 !important;
    --mutedLight: #b2c0d92c !important;
    --grey: #F2F5F9 !important;
    
    --primaryAccent: #483bd5 !important;
    --primaryColor: #ffffff !important;

    --backgroundLighter: #F5F6FF !important;
    --background: #EEEFF9 !important;
    --backgroundDarker: #E4E4ED !important;
    --backgroundDarkest: #B3B3BA !important;
    
    --error: #E9846A !important;
    --success: #88DC8E !important;

    --shadow: #CCD5E5 !important;

    --borderColor: var(--bs-gray-400) !important;
    --borderRadius: 15px !important;
    --borderRadiusButton: .375rem !important;

    --bodyMargin: 46px !important;
    --collapsedSidebarWidth: 75px !important;
    --topNavHeight: 50px !important;

    --contentMargin: min(5%, 162px) !important;

    --pageBorderRadius: 100px !important;
    --sideNavBorderRadius: 14px !important;

    --sideNavPaddingLeft: 66px !important;
}


@media screen and (max-width: 1500px) {
    :root {
        --contentMargin: min(2%, 20px) !important;
    }
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: var(--borderRadius);
}

::-webkit-scrollbar {
    width: 14px;
}

::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #DDDDDD;
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: var(--muted);
}
