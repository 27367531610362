.office-inbox {
    height: 100%;
    overflow-x: hidden;
}

.office-inbox-team {
    height: calc(100% - 80px);
}

.office-inbox-actions {
    height: 40px;
}

.office-inbox-content {
    height: calc(100% - 100px);
}

@media screen and (max-width: 1000px) {
    .office-inbox {
        height: calc(100% - 85px);
    }
    
    .office-inbox-team {
        height: calc(100% - 135px);
    }
}