.tab-switcher-highlight {
    transition: all 0.1s ease;
    border-radius: 3px;
    max-width: 100%;
}

.tab-switcher {
    width: fit-content;
}

.tab-switcher-regular {
    border-radius: 3px;
    min-height: 42px;
    font-size: 1.2em;
}

.tab-switcher-small {
    background-color: var(--backgroundLighter);
    min-height: 24px;
}