.editor {
    border-radius: var(--borderRadiusButton) !important;
    height: 100%;
    width: 100%;
}

.editor-field {
    border: 1px solid var(--secondary) !important;
}

.ck {
    box-shadow: none !important;
}

.ck-editor__main {
    height: calc(100% - 40px) !important;
}

.ck-sticky-panel,
.ck-editor__main, 
.ck-editor__editable,
.ck-toolbar,
.ck-content {
    border: none !important;
    outline: none !important;
}

.ck-editor__main {
    color: black !important;
}

.ck-dropdown__panel {
    overflow-y: auto;
    max-height: 100px;
}

.ck-editor,
.ck-content {
    width: 100% !important;
    max-height: 340px;
}

.ck-content {
    max-height: 300px;
}