.type-select-item {
    border-radius: var(--borderRadius);
    overflow: hidden;
    padding-top: 3px;
    padding-bottom: 3px;
}

.type-select-item-inactive .type-select-item-background {
    filter: saturate(0);
    opacity: 0.3;
}


.type-select-item-active .type-select-item-background {
}

@media screen and (any-pointer) {

    .type-select-item:hover {
        cursor: pointer;
    }

    .type-select-item:hover:not(.type-select-item-active) .type-select-item-background {
        opacity: 0.5;
        filter: saturate(1)
    }
}