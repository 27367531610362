.pill {
    padding: 0.25em 1em;
    width: fit-content;
    transition: all 0.15s ease;
    border-radius: var(--borderRadius);
}

.pill-clickable {
    user-select: none;
}

@media (any-pointer) {
    .pill-clickable:hover {
        cursor: pointer;
    }
}

@media screen and (max-width: 1000px) {
    .pill {
    }
}