.searchable-combo-box-input-container {
}

.searchable-combo-box-input {
    box-shadow: 0px 0px 8px var(--shadow) !important;
}

.searchable-combo-box-item-container {
    background-color: white;
}

.searchable-combo-box-items {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}