.tenant-delete-token-container {
    transition: all 0.3s ease;
}

.tenant-delete-token-container-hidden {
    max-height: 0;
}

.tenant-delete-token-container-expanded {
    max-height: 200px;
}