.create-ticket-form-first-message-field {
    resize: none;
    min-height: 300px !important;
    max-height: 300px !important;
    max-width: 100%;
    overflow-y: auto;
    height: 100%;
}

.create-ticket-form-first-note-field {
    resize: none;
}