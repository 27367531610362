@keyframes officeMessageViewAppear {
    from {
        transform: scale(0);
    }
}

.office-inbox-message-view {
    max-height: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--backgroundLighter);
    border: 1px solid var(--backgroundDarker);
}

.office-message-view-content {
    background-color: white;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
}


@media screen and (max-width: 1000px) {
    .office-inbox-message-view {
        position: absolute;
        height: 100%;
        right: 0;
        overflow: hidden;
        top: 0;
        max-width: 100%;
        animation: officeMessageViewAppear 0.15s ease;
    }

    .office-message-view-content {
        height: 100%;
    }
}