@keyframes escalateTicketPopUpAppear {
    from {
        max-height: 0;
    }
}

.escalate-ticket-available-users-popup {
    transition: all 0.15s ease;
    max-height: 200px;
    height: 100%;
    animation: escalateTicketPopUpAppear 0.3s ease;
}

.escalate-ticket-available-users-popup-expanded {
    transform: translateY(0);
    opacity: 1;
    border: 1px solid var(--backgroundDarker);
    border-radius: var(--borderRadiusButton);
    background-color: white;
    overflow-y: auto;
}

@media (any-pointer) {
    .escalate-ticket-user-button:hover {
        background-color: var(--backgroundDarker);
        cursor: pointer;
    }
}