.otp-field {
    height: 5vh;
    width: min(15%, 4em);
    font-size: 2em !important; 
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    outline: none;
    color: var(--primary);
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-500);
    transition: all 0.15s ease;
}

.otp-field-focus {
    background-color: aliceblue;
    border-color: var(--primary);
}

.otp-field-error {
    color: red;
    border-color: red;
}