@keyframes copyToClipboardButtonCooldown {
    to {
        max-width: 0;
    }
}

.copy-to-clipboard-button {
    border: 1px solid var(--primaryAccent);    
    border-radius: 5px;
    outline: none;
    background-color: white;
    color: var(--bs-gray-700);
}

.copy-to-clipboard-button:disabled {
    border-color: var(--success);
    color: var(--success);
}

.copy-to-clipboard-button-background {
    opacity: 0.1;
    max-width: 100%;
}

.copy-to-clipboard-button:disabled .copy-to-clipboard-button-background {
    background-color: var(--success);
    animation: copyToClipboardButtonCooldown 1s linear;
}

@media (any-pointer) {
    .copy-to-clipboard-button:hover:not(:disabled) {
        background-color: var(--bs-gray-300);
    }   
}