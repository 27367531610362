@keyframes officeMailFormAppear {
    from {
        max-height: 0;
        max-width: 0;
    }
}

.office-mail-form-field {
    min-height: 400px !important;
    max-height: 600px !important;
}

.office-mail-form {
    animation: officeMailFormAppear 0.3s ease;
    transition: all 0.3s ease;
    position: fixed;
    bottom: 0;
    right: 15px !important;
    z-index: 99999;
    overflow: hidden;
    border-radius: var(--borderRadius);
    border: 1px solid var(--backgroundDarker);
    background-color: var(--backgroundLighter);
}

.office-mail-form-minimized {
    max-height: 55px !important;
}

.office-mail-form-content {
    transition: all 0.3s ease;
    height: 100%;
}

.office-mail-form-content-minimized {
    opacity: 0;
}

@media screen and (min-width: 1000px) {
    .office-mail-form {
        max-height: 800px;
        max-width: 800px;
    }
}

@media screen and (max-width: 1000px) {
    .office-mail-form {
        max-height: 100%;
        height: 100%;
    }
}