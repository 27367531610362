@keyframes expandableContentAppear {
    from {
        max-height: 0;
        overflow-y: hidden;
    }

    to {
        overflow-y: hidden;
    }
}

.expandable {
    position: relative;
}

.expandable-expander {
    width: fit-content;
}

.expandable-content-background {
    background-color: white;
    box-shadow: 0px 0px 8px var(--shadow);
}

.expandable-content {
    z-index: 99999;
    position: fixed;
    max-height: 450px;
    width: fit-content;
    min-width: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: var(--borderRadius);
}

.expandable-content-expand-from-right {
    right: 0%;
}

.expandable-content-expand-from-left {
    left: 0%;
}

@media (any-pointer) {
    .expandable-expander {
        cursor: pointer;
    }
}