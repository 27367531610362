.image-select-file-input {
    opacity: 0;
    position: absolute;
    height: 0.1px;
    width: 0.1px;
}

.image-select {
    border-radius: var(--borderRadius);
    height: 100px;
    width: 100%;
    border: 1px solid var(--bs-gray-300);
    background-color: var(--background);
    overflow: hidden;
}

.image-select-drag-active {
    border-width: 1px;
    border-style: dashed;
    border-color: var(--primaryAccent);
    background-color: #00000010;
}

.image-select-icon {
    color: var(--textDisabled);
}

.image-select-upload-button {
    text-decoration: underline;
}

.image-select-image {
    overflow: hidden;
    width: auto;
    height: 100%;
    object-fit: cover !important;
}

.image-select-instruction-overlay {
    transition: all 0.3s ease;
}

.image-select-instruction-overlay * {
    color: white;
}

.image-select-instruction-overlay-background {
    background-color: black;
    opacity: 0.5;
    transition: all 0.3s ease;
}

.image-select-instruction-overlay-hidden,
.image-select-instruction-overlay-background-hidden  {
    opacity: 0;
}

@media (any-pointer) {
    
    .image-select-upload-button:hover {
        color: var(--primaryAccent);
        text-decoration: none;
        cursor: pointer;
    }   

    .image-select-instruction-overlay .image-select-upload-button:hover {
        color: var(--primaryAccent);
    }
}