@keyframes expandableContentAppear {
    from {
        max-height: 0;
        overflow-y: hidden;
    }

    to {
        overflow-y: hidden;
    }
}

.select-button {
    position: relative;
}

.select-button-expander {
    width: fit-content;
}

.select-button-content {
    z-index: 99999;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    animation: expandableContentAppear 0.3s ease;
    border-radius: var(--borderRadius);
    box-shadow: 0px 0px 8px var(--shadow);
    background-color: white;
}


.select-button-content-expand-from-right {
    right: 0%;
}

.select-button-content-expand-from-left {
    left: 0%;
}

@media (any-pointer) {
    .select-button-expander:hover {
        cursor: pointer;
    }

    .select-button-expander:hover * {
        color: var(--secondary) !important;
    }

    .select-button-item:hover {
        cursor: pointer;
    }

    .select-button-item:hover * {
        color: var(--primary) !important;
    }
}