.productivity-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
}

.own-statistics-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}

@media screen and (max-width: 768px) {
    .productivity-grid {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 450px) {
    .own-statistics-grid {
        grid-template-columns: 1fr;
    }
}
