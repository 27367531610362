body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  padding: 0 !important;
  background-color: var(--primary);
}

input, textarea {
  font-size: 1em !important;
  box-shadow: none !important;
  transition: all 0.15s ease !important;
}

#root {
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden;
}