@keyframes assignTicketToOtherTeamMembersMenuAppear {
    from {
        max-height: 0;
    }
}

.assign-ticket-to-other-team-popup {
    background-color: white;
    border-radius: var(--borderRadiusButton);
    border: 1px solid var(--backgroundDarker);
    transition: all 0.15s ease;
    overflow-y: auto;
    height: 100%;
    max-height: 400px;
    animation: assignTicketToOtherTeamMembersMenuAppear 0.3s ease;
}

.assign-ticket-to-other-team-container {
    z-index: 5 !important;
}

.assign-ticket-to-other-team-popup-expanded {
    transform: translateY(0);
    opacity: 1;
}

.current-team-pop-up {
    right: calc(100% + 10px);
    border-radius: var(--borderRadius);
    overflow: hidden;
    background-color: white;
    transition: all 0.3s ease;
}