.mail-item {
    border-top: 3px solid var(--secondary);
}

.mail-item-container {
    width: 300px;
}

.mail-item-read {
    border-color: var(--backgroundLighter);
}

.mail-item-active {
    background-color: var(--backgroundLighter);
}

.mail-item-background-read {
    background-color: var(--backgroundLighter);
    opacity: 1;
}

.mail-item-background-active {
    opacity: 0.25 !important;
}

.mail-item-content {
    background-color: transparent;
}

.mail-item-preview {
    color: #A0A0A0;
}


@media (any-pointer) {
    .mail-item:hover {
        cursor: pointer;
        background-color: var(--grey);
    }

    .mail-item:hover .mail-item-background {
        background-color: var(--primaryAccent);
        opacity: 0.25 !important;
    }

    .mail-item:hover .mail-item-preview {
    }
}

@media screen and (min-width: 1000px) {
    .mail-item-container {
        width: 350px;
    }
}

@media screen and (max-width: 1000px) {
    .mail-item-container {
        width: 100%;
    }
}