.combo-box-item {
    position: relative;
    background-color: white;
}

.combo-box-item-background {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primaryAccent);
    opacity: 0;
}

@media (any-pointer) {
    .combo-box-item:hover {
        cursor: pointer;
    }

    .combo-box-item:hover .combo-box-item-background {
        opacity: 0.1;
    }
}