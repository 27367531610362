@keyframes ticketChatFormUserTypingNoticeAppear {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
}

.ticket-chat-container {
    overflow-y: auto;
    overflow-x: hidden;
}

.ticket-chat-message-input {
    height: 100%;
    resize: none;
    border: none;
    outline: none;
    font-size: 1em !important;
}

.ticket-chat {
    overflow: hidden;
}

.ticket-chat-form-user-typing-notice {
    opacity: 0.5;
    width: 100%;
    top: -10px;
    animation: ticketChatFormUserTypingNoticeAppear 0.1s ease;
}

.ticket-internal-subject-form {
    width: fit-content;
}

.ticket-chat-internal-subject-field-read-only {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
}