.imposter-session-user-indicator {
    position: fixed;
    top: 5px;
    width: 450px;
    left: calc(50% - 75px);
    padding: 10px;
    background-color: var(--error);
    z-index: 99999999;
    border-bottom-right-radius: var(--borderRadius);
    border-bottom-left-radius: var(--borderRadius);
}