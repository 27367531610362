.field-with-label {
    border: 1px solid var(--muted) !important;
}

.field-with-label-rounded {
    border-radius: 50px;
    box-shadow: 0px 0px 8px var(--shadow) !important;
}

.field-with-label::placeholder {
    color: var(--muted);
}

@media screen and (any-pointer) {
    .field-with-label:hover:not(.field-no-hover) {
        background-color: var(--mutedLight);
        border-color: var(--secondary) !important;
    }

    .field-with-label:focus:not(.field-no-hover) {
        background-color: white;
        border-color: var(--secondary) !important;
    }
}